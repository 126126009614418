import React from "react";
import { Title } from "../Title/Title";
import "./skill.css";
const Skill = ({ skillRef }) => {
  return (
    <div className="skill-container" ref={skillRef}>
      <div className="skill-title">
        <Title main="SKILLS" sub="EXPERT AT" />
      </div>
      <div className="skill-list">
        <ul>
          <li>
            I do perform VAPT, SOC audits and impliment compliances across
            various organizations.
          </li>
          <li>Expert in Social Media Threat Intelligence & Investigations.</li>
          <li>Surveillance Specialist.</li>
          <li>
            Flying Drones - Advanced Warfare tactics is among my skillset.
          </li>
        </ul>
      </div>
      <div className="skill-exp">
        <h2>WORK EXPERIENCE</h2>
        <ul>
          <li>
            2021 - Present Security Researcher TSARO Labs Michigan, USA
            Hyderabad, INDIA
          </li>
          <li>2018 - 2020 Cloud Security Engineer Confidential New Jersey</li>
          <li>
            2015 - 2018 Network Administrator Ananya Technologies New Jersey
          </li>
          <li>2013-2014 Research Assistant at RCI-DRDO Labs, HYD,India.</li>
        </ul>
      </div>
    </div>
  );
};

export default Skill;
