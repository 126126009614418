import React from "react";
import About from "../../About/About";
import Blog from "../../Blog/Blog";
import Companies from "../../Companies/Companies";
import Contact from "../../Contact/Contact";
import Footer from "../../Footer/Footer";
import Hero from "../../Hero/Hero";
import Skill from "../../Skill/Skill";
import Slider from "../../Slider/Slider";
import "./home.css";

const Home = ({
  homeLiRef,
  aboutLiRef,
  contactLiRef,
  skillRef,
  galleryRef,
  blogRef,
  scrollToRef
}) => {
  return (
    <div className="main-container">

      <Hero homeLiRef={homeLiRef} blogRef={blogRef} scrollToRef={ scrollToRef} />
      <About aboutLiRef={aboutLiRef} />
      <Blog blogRef={blogRef} />
      {/* <Certification/> */}
      <Skill skillRef={skillRef} />
      <Companies />
      <Slider galleryRef={galleryRef} />
      <Contact contactLiRef={contactLiRef} />
      <Footer />
    </div>
  );
};

export default Home;
