
import React from 'react'
import profile from '../../assets/logo/profile21.jpg'
import './sidebar.css'
const Sidebar = ({
  homeLiRef,
  aboutLiRef,
  skillRef,
  contactLiRef,
  scrollToRef,
  galleryRef,
  blogRef,
}) => {
  return (
    <nav className="sidebar-container">
      <div className="profile-pic-section">
        <div className="profile">
          {/* <img src={profile} alt="Sairam Palabindela " loading="lazy" /> */}
        </div>
        <p>Sairam Palabindela</p>
      </div>
      <div className="sidebar-menu">
        <ul>
          <li onClick={() => scrollToRef(homeLiRef)}>Home</li>
          <li onClick={() => scrollToRef(aboutLiRef)}>About me</li>
          <li onClick={() => scrollToRef(skillRef)}>Skills & Experience </li>
          <li onClick={()=>scrollToRef(blogRef)}>Blog</li>
          <li onClick={() => scrollToRef(galleryRef)}>Events Gallery</li>

          {/* <li>Blog</li> */}
          <li onClick={() => scrollToRef(contactLiRef)}>Contact</li>
        </ul>
      </div>
      <div className="sidebar-links">
        <a
          href="https://www.linkedin.com/in/sairampalabindela"
          target={"_blank"}
          rel="noreferrer"
        >
          <i className="fa-brands fa-linkedin" style={{ fontSize: "2rem" }}></i>
        </a>
      </div>
    </nav>
  );
};

export default Sidebar