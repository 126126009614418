import endNowFoundation from "../../assets/logo/end_now_foundation.png";
import ameri from "../../assets/logo/ameri.webp";
import telenganaPolice from "../../assets/logo/tel_police.webp";
import taskTelengana from "../../assets/logo/task.png";
import govtTelengana from "../../assets/logo/telengana_govt.png";
import tcs from "../../assets/logo/tcs.png";
import infosys from "../../assets/logo/infosys.png";
import ieee from "../../assets/logo/ieee.png";
import interNetSociety from "../../assets/logo/internet-society.png";


export const gallery = [
  {
    img: endNowFoundation,
    alt: "End now foundation",
  },
  {
    img: ameri,
    alt: "Ameri Solutions",
  },
  {
    img: telenganaPolice,
    alt: " Telangana Police",
  },
  {
    img: taskTelengana,
    alt: "TASK-Telangana",
  },
  {
    img: govtTelengana,
    alt: "government of telangana",
  },
  {
    img: tcs,
    alt: "TCS",
  },
  {
    img: infosys,
    alt: "Infosys",
  },
  {
    img: ieee,
    alt: "IEEE",
  },
  {
    img: interNetSociety,
    alt: " Internet society,",
  },

];
