import React, { useEffect, useRef, useState } from "react";
import Home from "./components/pages/Home/Home";
import "./App.css";
import Loader from "./components/Loader/Loader";
import Navbar from "./components/Navbar/Navbar";
const App = () => {
  const [isLoading, setLoader] = useState(true);
  const handleReadyState = () => setLoader(document.readyState === "complete" ? false : true)
  

  const homeLiRef = useRef(null);
  const aboutLiRef = useRef(null);
  const skillRef = useRef(null);
  const contactLiRef = useRef(null);
  const galleryRef = useRef(null)
  const blogRef = useRef(null)

    const scrollToRef = (ref) => {
      window.scrollTo({ top: ref.current.offsetTop, behavior: "smooth" });
    };
  
  useEffect(() =>
  {
    document.onreadystatechange = function () {
      if (document.readyState === "complete") {
        handleReadyState()
      }
    };
  }, [isLoading]);

  // if (isLoading) return <Loader />;
  return (
    <>
      <Navbar
        homeLiRef={homeLiRef}
        aboutLiRef={aboutLiRef}
        skillRef={skillRef}
        contactLiRef={contactLiRef}
        galleryRef={galleryRef}
        scrollToRef={scrollToRef}
        blogRef={blogRef}
      />
      <Home
        homeLiRef={homeLiRef}
        aboutLiRef={aboutLiRef}
        skillRef={skillRef}
        contactLiRef={contactLiRef}
        galleryRef={galleryRef}
        blogRef={blogRef}
        scrollToRef={scrollToRef}
      />
    </>
  );
};

export default App;
