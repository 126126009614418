import React, {  useState } from "react";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";

const Navbar = ({
  homeLiRef,
  aboutLiRef,
  skillRef,
  contactLiRef,
  scrollToRef,
  galleryRef,
  blogRef,
}) => {
  const [sideNavBarStatus, setSideNavBarStatus] = useState(false);

  return (
    <>
      <Sidebar
        homeLiRef={homeLiRef}
        aboutLiRef={aboutLiRef}
        skillRef={skillRef}
        contactLiRef={contactLiRef}
        scrollToRef={scrollToRef}
        galleryRef={galleryRef}
        blogRef={blogRef}
      />
      <Topbar
        sideNavBarStatus={sideNavBarStatus}
        setSideNavBarStatus={setSideNavBarStatus}
        homeLiRef={homeLiRef}
        aboutLiRef={aboutLiRef}
        skillRef={skillRef}
        contactLiRef={contactLiRef}
        scrollToRef={scrollToRef}
        galleryRef={galleryRef}
        blogRef={blogRef}
      />
    </>
  );
};

export default Navbar;
