import React from "react";
import vid from '../../assets/hero.mp4'
import "./hero.css";
import Typed from "react-typed";
import resume from '../../assets/resume.docx'
import Particle from "../Particles/Particle";
import Particles from "react-tsparticles";
const Hero = ({ homeLiRef,scrollToRef,blogRef }) => {

  
  return (
    <div className="hero-container" ref={homeLiRef}>
      {/* <Parallax bgImage={image1} strength={500}> */}
      {/* <div className="video-bg">
        <video
          autoPlay
          playsInline
          loop
          type="video/mp4"
          autobuffer="autobuffer"
          muted="muted"
        >
           <source src={vid} type="video/mp4"/>
         
        </video>
      </div>
      <div className="hero-mask"></div> */}
      <Particle />

      <div style={{ height: 1000 }}>
        <div className="hero-box">
          <div className="hero-info-box">
            <p className="hero-info-p">Hello!</p>
            <h1 className="hero-info-name">
              <span style={{ color: "#6b42f8" }}>I'm </span>
              <Typed
                strings={[
                  " a Cyber Security Expert",
                  " a Cyber Security Researcher ",
                  " a Cyber Security consultant",
                ]}
                typeSpeed={40}
                backSpeed={40}
                loop
              />
            </h1>
          </div>
          <div className="hero-btn">
            <a
              role={"button"}
              href={resume}
              download="Sairam Resume One Page.docx"
              className="btn btn-primary"
            >
              Download My CV 📄
            </a>
            <button
              className="btn btn-secondary"
              onClick={() => scrollToRef(blogRef)}
            >
              Explore My Blog 🔗
            </button>
            {/* site developed by Aravind ks http://github.com/iamAravindks */}
          </div>
        </div>
      </div>
      {/* </Parallax> */}
    </div>
  );
};

export default Hero;

