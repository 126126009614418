import React from 'react'
import { Title } from "../Title/Title";
import './blog.css'

const Blog = ({ blogRef }) =>
{
  return (
    <div className="blog-container" ref={blogRef}>
      <div className="blog-head">
        <Title main="" sub="EXPLORE MY BLOG" />
      </div>
      <div className="btn-container">
        <a
          href="https://www.linkedin.com/pulse/uncharted-maritime-cyber-risk-india-needs-address-immediately-p"
          target={"_blank"}
          rel="noreferrer"
          role={"button"}
          className="btn-blog blog1"
        >
          The Uncharted Maritime - A Cyber Risk, that India needs to address
          Immediately.
        </a>
        <a
          href="https://www.linkedin.com/pulse/business-e-mail-compromise-bec-hackers-most-efficient-sairam-p"
          target={"_blank"}
          rel="noreferrer"
          role={"button"}
          className="btn-blog blog2"
        >
          Business E-mail Compromise (BEC) - The Hackers most efficient way of
          attacking Business!
        </a>
      </div>
    </div>
  );
};

export default Blog