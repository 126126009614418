import React from "react";
import { Title } from "../Title/Title";
import "./companies.css";
import Gallery from "./Gallery";
import tasro from "../../assets/logo/tasro_labs.png";

const Companies = () => {
  return (
    <div className="work-container">
      <div className="head">
        <Title main="EXPERIENCE" sub="COLLABORATED WITH" />
      </div>
      <div className="work-gallery-ceo">
        <img src={tasro} alt="tasro" />
      </div>
      <Gallery />
    </div>
  );
};

export default Companies;
