import React, { useEffect, useState } from "react";
import styles from "./header.module.css";
import TopbarMenu from "./TopbarMenu";

const Topbar = ({
  sideNavBarStatus,
  setSideNavBarStatus,
  homeLiRef,
  aboutLiRef,
  skillRef,
  contactLiRef,
  scrollToRef,
  galleryRef,
  blogRef
}) => {
  const [isNavbr, setIsNavbr] = useState(false);

  const setNavbar = () => setIsNavbr(window.scrollY >= 80 && true);

  useEffect(() => {
    window.addEventListener("scroll", setNavbar);
    // document.body.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("scroll", setNavbar);
      // document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={
        isNavbr
          ? `${styles.topBarContainer} ${styles.setNavbar}`
          : styles.topBarContainer
      }
    >
      <div className={styles.topBarBrand}>
        <h2>Sairam Palabindela</h2>
      </div>
      <div className={styles.topBarMenu}>
        <a
          href="https://www.linkedin.com/in/sairampalabindela"
          target={"_blank"}
          rel="noreferrer"
        >
          <i className="fa-brands fa-linkedin" style={{ fontSize: "2rem" }}></i>
        </a>
      </div>
      <div
        className={`${styles.hamburger} ${
          sideNavBarStatus ? styles.opened : ""
        }`}
        onClick={() => {
          sideNavBarStatus
            ? setSideNavBarStatus(false)
            : setSideNavBarStatus(true);
        }}
        aria-label={styles.hamburger}
        aria-expanded={sideNavBarStatus ? "opened" : ""}
        style={{ zIndex: 100000 }}
      >
        <svg
          className={styles.svgImg}
          width="100"
          height="100"
          viewBox="0 0 100 100"
        >
          <path
            className={`${styles.line} ${styles.line1}`}
            d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
          />
          <path className={`${styles.line} ${styles.line2}`} d="M 20,50 H 80" />
          <path
            className={`${styles.line} ${styles.line3}`}
            d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
          />
        </svg>
      </div>
      {sideNavBarStatus && (
        <TopbarMenu
          homeLiRef={homeLiRef}
          aboutLiRef={aboutLiRef}
          skillRef={skillRef}
          contactLiRef={contactLiRef}
          scrollToRef={scrollToRef}
          setSideNavBarStatus={setSideNavBarStatus}
          galleryRef={galleryRef}
          blogRef={blogRef}
        />
      )}
    </div>
  );
};

export default Topbar;
