import React from "react";
import { v4 as uuid } from "uuid";
import "./companies.css";
import { gallery } from "./data";
import ftcci from "../../assets/logo/ftcci.png";

const Gallery = () => {
  return (
    <div className="work-gallery">
      {gallery.map((item) => (
        <div className="work-gallery-img" key={uuid()}>
          <img src={item.img} alt={item.alt} loading="lazy" />
        </div>
      ))}
      <div className="work-gallery-img last">
        <img src={ftcci} alt="ftcci" />
      </div>
    </div>
  );
};

export default Gallery;
