import AwesomeSlider from "react-awesome-slider";
import cli1 from "../../assets/images/sliderimg/cli.jpeg";
import cli2 from "../../assets/images/sliderimg/cli2.jpeg";
import cli3 from "../../assets/images/sliderimg/cli3.jpeg";
import cli4 from "../../assets/images/sliderimg/cli4.jpeg";
import cli5 from "../../assets/images/sliderimg/cli5.jpeg";
import cli6 from "../../assets/images/sliderimg/cli6.jpeg";
import cli7 from "../../assets/images/sliderimg/cli7.jpeg";
import cli8 from "../../assets/images/sliderimg/cli8.jpeg";

import "react-awesome-slider/dist/styles.css";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";
import withAutoplay from "react-awesome-slider/dist/autoplay";

import "./slider.css";

const AutoplaySlider = withAutoplay(AwesomeSlider);
const buttonStyle = {
  padding: "15px",
  borderRadius: "50%",
  background: "#6b42f8",
  opacity: 0.7,
  fontSize: "20px",
  width:"60px"
};
const Slider = ({ galleryRef }) => {
  return (
    <div className="slider-container" ref={galleryRef}>
      <AutoplaySlider
        // play={true}
        cancelOnInteraction={false} // should stop playing on user interaction
        interval={2000}
        organicArrows={false}
        buttonContentRight={<p className="awsBtn">{">"}</p>}
        buttonContentLeft={<p className="awsBtn">{"<"}</p>}
      >
        <div style={{ zIndex: 2 }}>
          <div>
            <img alt="Wow" src={cli1} />
          </div>
        </div>
        <div>
          <img alt="Wow" src={cli2} />
        </div>
        <div>
          <img alt="Wow" src={cli3} />
        </div>
        <div>
          <img alt="Wow" src={cli4} />
        </div>
        <div>
          <img alt="Wow" src={cli5} />
        </div>
        <div>
          <img alt="Wow" src={cli6} />
        </div>
        <div>
          <img alt="Wow" src={cli7} />
        </div>
        <div>
          <img alt="Wow" src={cli8} />
        </div>
      </AutoplaySlider>
    </div>
  );
};

export default Slider;
