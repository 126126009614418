import React from 'react'
import './topbarmenu.css'
const TopbarMenu = ({
  homeLiRef,
  aboutLiRef,
  skillRef,
  contactLiRef,
  scrollToRef,
  setSideNavBarStatus,
  galleryRef,
  blogRef 
}) => {
  const handleClick = (ref) => {
    scrollToRef(ref);
    setSideNavBarStatus(false);
  };
  return (
    <div className="topbarmenu-container" data-aos="fade-down">
      <div className="topbarmenu-menu">
        <ul>
          <li onClick={() => handleClick(homeLiRef)}>Home</li>
          <li onClick={() => handleClick(aboutLiRef)}>About me</li>
          <li onClick={() => handleClick(skillRef)}>Skills & Experience </li>
          <li onClick={() => handleClick(blogRef)}>Blog</li>
          <li onClick={() => handleClick(galleryRef)}>Events Gallery</li>
          {/* <li>Blog</li> */}
          <li onClick={() => handleClick(contactLiRef)}>Contact</li>
        </ul>
      </div>
    </div>
  );
};

export default TopbarMenu