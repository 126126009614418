import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <footer className="footer-container">
      Made by
      <a href="http://www.widereachmedia.in/" target="_blank" rel="noreferrer">
        <i className="fa-solid fa-globe"></i> widereachmedia
      </a>
      © 2022
    </footer>
  );
}

export default Footer