import React from 'react'
import './title.css'
export const Title = ({main,sub}) => {
  return (
      <div className="title-container">
          <h1 className="main-title-opacity">{main}</h1>
          <h3 className="sub-title" data-aos="fade-right">{sub}</h3>
    </div>
  )
}
