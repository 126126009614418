import React from "react";
import { Title } from "../Title/Title";
import './contact.css'
import qr from '../../assets/images/index.jpg'
const { REACT_APP_CLASS_NAME_FORM } = process.env
const Contact = ({ contactLiRef }) => {
  return (
    <div className="contact-container" ref={contactLiRef}>
      <Title main="CONTACT" sub="Get in Touch" />
      <div className="contact-box">
        <div className="contact-details">
          <div className="contact-me">
            <h2>CONTACT DETAILS</h2>
            <p>
              <i className="fa-solid fa-envelope"></i>ram1515@outlook.com
            </p>
          </div>
          <div className="follow">
            <h2>Follow me</h2>
            <div className="follow-links">
              <a
                href="https://www.linkedin.com/in/sairampalabindela"
                target={"_blank"}
                rel="noreferrer"
              >
                <i className="fa-brands fa-linkedin" style={{fontSize:"3rem"}}></i>
              </a>
            </div>
            <div className="qr-code">
              <img src={qr} alt="https://www.linkedin.com/in/sairampalabindela"/>
            </div>
          </div>
        </div>
        <div class={REACT_APP_CLASS_NAME_FORM} id="form"></div>
      </div>
    </div>
  );
};

export default Contact;
