import React, { useState } from "react";
import { Title } from "../Title/Title";
import "./about.css";
const { REACT_APP_CLASS_NAME } = process.env

const About = ({ aboutLiRef }) => {
  const initState = {
    year1: 8,
    year2: 20,
  };
  const [year, setYear] = useState(initState);

  return (
    <section className="about-container" ref={aboutLiRef}>
      <div className="about-title">
        <Title main="ABOUT ME" sub="Know Me More" />
      </div>

      <div className="about-info-box">
        {/* <div className="about-image">
          <img src={pic} alt="Sairam Palabindela" />
        </div> */}
        <div className="about-info">
          <div className="about-me">
            <p className="about-p">
              I am a certified Cyber Security expert currently working as a
              Security Researcher with TSARO Labs an MNC based in Michigan, USA.
              I hold Dual Master’s in Information Assurance and Internet
              architecture & Web Designing from United States. I was trained by
              <b> FEMA</b>(Federal Emergency Management Agency) a division under
              <b> Department of Homeland Security (USA)</b> in Cyber Incident
              Analysis and response, Cyber Ethics, Information Security,
              Surveillance awareness, ICS (Industrial Control Systems),
              Operational Security, Shopping center and expert in Counter
              Intelligence.Simillarly, I also trained multiple Intelligence
              agencies in DHS-USA, and nurtured many professionals in the domain
              of security intelligence. In state of Telangana, I trained
              multiple Police officers under Cyber Security and Awareness
              Campaigns of Digital Wellbeing.
            </p>
            <p className="about-p">
              Proudly to say. I have a well diverse portfolio under the
              Information Security domain. In the part time I collaborate with
              industry domain organizations like <b>CSI</b> (Computer Society of
              India), <b>IEEE</b> (Institute of Electrical and Electronics
              Engineers) , <b>FTCCI</b> (Federation of Telangana Chambers of
              Commerce and Industry) , <b>IS</b> (Internet Society) and
              contributes to the global technological developments.
            </p>
            <p className="about-p">
              I also works with an NGO named <b>“End Now Foundation”</b> to
              promote “Digital Literacy & Digital Wellbeing” and been a part of
              many public and private events for the widespread. I am also a
              professional trainer, few of my clients include different Police
              departments, multiple agencies, and Corporate people from the
              Cyber Security point of view.
            </p>
          </div>
          <div className="about-list">
            <p>Went to</p>
            <ul>
              <li>Vignan University, Guntur - INDIA</li>
              <li>New Jersey Institute of Technology, New Jersey, USA</li>
              <li>Penn State University, Pennsylvania, USA</li>
              <li>Wilmington University, Delaware, USA</li>
            </ul>
            <div className={REACT_APP_CLASS_NAME}></div>
          </div>
        </div>
        <div className="about-exp">
          <div className="about-exp-year">
            <h4>{year.year1}+</h4>
            <p>Years of Experience</p>
          </div>
          <div className="about-exp-year">
            <h4>{year.year2}+</h4>
            <p>Courses & Certificates</p>
          </div>
        </div>
      </div>
    </section>
  );
};



export default About;
