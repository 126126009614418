import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

export default function Particle() {
  const particlesInit = async (main) => {

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };

  return (
    <Particles
      init={particlesInit}
      params={{
        fpsLimit: 60,
        particles: {
          color: {
            value: "#eee",
          },
          links: {
            enable: true,
            color: "#eee",
            distance: 150,
          },
          move: {
            enable: true,
          },
          },
        fullScreen:{enable:false}
      }}
          className="particle-hero"
    />
  );
}
